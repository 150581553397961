import { logInfo } from '@mop/shared/utils/logger';
export default defineNuxtPlugin((nuxtApp) => {
  const { $cookie } = useNuxtApp();
  const isDebugCookieEnabled = Boolean($cookie.get(COOKIE.DEBUG));

  function log(value: string) {
    if (!isDebugCookieEnabled) {
      return;
    }
    logInfo(`DEBUG: ${value}`, true);
  }

  nuxtApp.provide('debug', { log });
});
