/**
 * Isomorphic cookie storage plugin
 */
import type { CookieOptions } from '@mop/shared/utils/cookie';
import cookie from '@mop/shared/utils/cookie';
import { securedWrap } from '@mop/shared/utils/securedWrap';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide(
    'cookie',
    securedWrap({
      get(cookieName: string): string {
        return cookie().get(cookieName);
      },

      store(cookieName: string, cookieValue: string, cookieOptions: CookieOptions = {}) {
        cookie().store(cookieName, cookieValue, cookieOptions);
      },

      remove(cookieName: string, cookieOptions: CookieOptions = {}) {
        cookie().remove(cookieName, cookieOptions);
      },

      removeByPattern(cookiePattern: string) {
        cookie().removeByPattern(cookiePattern);
      },
    }),
  );

  const mopCookies: string = useRoute().query.mopcookies as string;
  if (mopCookies) {
    // Example /de-de?mopcookies=mopdebug:true;lang:fr-fr
    mopCookies.split(';').forEach((cookieString) => {
      const [cookieName, cookieValue] = cookieString.split(':');
      nuxtApp.vueApp.$nuxt.$cookie.store(cookieName, cookieValue);
    });
  }
});
